import React from 'react'
import { PackageListingsCard } from '@pcln/marketing'
import analytics from '@/shared-utils/analytics'
import buildGA4ObjectForPackageDealTiles from './deal-tiles'
import { PackagesCarouselGaCategories, DealDataResponse } from './types'
import { formatPackagesDateFromSearchLink } from '../../utils/date-helper'

type PackageDetails = {
  product: number
  title: string
}

export default function PackageCard({
  deal,
  gaCategory,
  index
}: {
  deal: DealDataResponse
  gaCategory: PackagesCarouselGaCategories
  index: number
}) {
  const {
    cityName,
    cityImageUrl,
    priceAmount,
    productName,
    description,
    searchLink
  } = deal
  const { formattedDepartureDate, formattedReturnDate, daysBetween } =
    formatPackagesDateFromSearchLink(searchLink)

  if (!formattedDepartureDate || !formattedReturnDate || !daysBetween) {
    // Ui requires departure/return date.
    return null
  }
  const handlePackageClick = () => {
    window.open(searchLink, '_blank')
    analytics.fireEvent({
      category: gaCategory,
      action: 'cached deal',
      label: cityName
    })
    const ga4Pkgs = buildGA4ObjectForPackageDealTiles({
      event: 'select_promotion',
      listings: [deal],
      selectedItemIndex: index
    })
    analytics.fireGA4Event(ga4Pkgs)
  }

  let packageDetails: Array<PackageDetails> = []
  if (productName === 'Hotel + Flight') {
    packageDetails = [
      {
        product: 1,
        title: 'Round-trip flight'
      },
      {
        product: 5,
        title: `Hotel for ${daysBetween} Nights`
      }
    ]
  }
  const packageSectionProps = {
    tripStartDate: formattedDepartureDate,
    tripEndDate: formattedReturnDate,
    packageDetails
  }
  return (
    <PackageListingsCard
      heading={cityName}
      packagesBadge={productName}
      imageUrl={cityImageUrl}
      price={priceAmount}
      priceBadge="Bundle & Save"
      priceType="per person"
      savingsDisplay={`${description}`}
      packageSectionProps={packageSectionProps}
      handleClick={handlePackageClick}
    />
  )
}
