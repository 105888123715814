import { format } from 'date-fns/format'
import { getDay } from 'date-fns/getDay'
import { setDay } from 'date-fns/setDay'
import { ANALYTICS_CATEGORY_HOME } from '@/constants/analytics'

type generateLinkArrayOptions = {
  flySearchCity: string
  driveSearchCity: string
  staySearchHref: string
  packageSearchHref?: string
}

function formatDriveSearchHref(driveSearchCity: string) {
  const today = new Date()
  const todayAsDay = getDay(today)
  const targetWeekendStart =
    todayAsDay >= 5 && todayAsDay <= 7
      ? `${format(setDay(today, 12), 'yyyyMMdd')}-12:00`
      : `${format(setDay(today, 5), 'yyyyMMdd')}-12:00`
  const targetWeekendEnd =
    todayAsDay >= 5 && todayAsDay <= 7
      ? `${format(setDay(today, 14), 'yyyyMMdd')}-12:00`
      : `${format(setDay(today, 7), 'yyyyMMdd')}-12:00`

  const driveSearchPath = '/drive/search/r'
  return `${driveSearchPath}/${driveSearchCity}/${encodeURI(
    driveSearchCity
  )}/${targetWeekendStart}/${targetWeekendEnd}/list`
}

export default function generateLinkArray({
  flySearchCity,
  driveSearchCity,
  staySearchHref,
  packageSearchHref
}: generateLinkArrayOptions) {
  const flySearchHref = `/flights?destination=${encodeURI(flySearchCity)}`
  const driveSearchHref = formatDriveSearchHref(driveSearchCity)

  const links = [
    {
      gaAction: 'Popular_Hotels_Destinations',
      gaCategory: ANALYTICS_CATEGORY_HOME,
      gaLabel: `Hotels Link | ${flySearchCity}`,
      href: `${staySearchHref}`,
      linkTitle: 'Hotels',
      target: '_blank'
    },
    {
      gaAction: 'Popular_Cars_Destinations',
      gaCategory: ANALYTICS_CATEGORY_HOME,
      gaLabel: `Cars Link | ${flySearchCity}`,
      href: `${driveSearchHref}`,
      linkTitle: 'Cars',
      target: '_blank'
    },
    {
      gaAction: 'Popular_Flights_Destinations',
      gaCategory: ANALYTICS_CATEGORY_HOME,
      gaLabel: `Flights Link | ${flySearchCity}`,
      href: `${flySearchHref}`,
      linkTitle: 'Flights',
      target: '_blank'
    }
  ]
  if (packageSearchHref) {
    links.push({
      gaAction: 'Popular_Flights_Destinations',
      gaCategory: ANALYTICS_CATEGORY_HOME,
      gaLabel: `Bundle & Save Link | ${flySearchCity}`,
      href: `${packageSearchHref}`,
      linkTitle: 'Bundle & Save',
      target: '_blank'
    })
  }
  return links
}
