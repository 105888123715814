import React from 'react'

export default function Miami() {
  return (
    <svg width="164" height="46">
      <defs>
        <path
          id="mia-path"
          d="M82.714 39.683l-13.4 21.104-7.224-21.104H50.533l-1.793 8.611h2.242L46 79.403h10.063l2.939-19.512 6.974 19.462 13.55-18.864-2.89 18.914h11.558l1.893-8.81h-2.292l4.932-30.96-10.013.05zm28.544 1.643a5.334 5.334 0 0 0-5.33-5.326c-2.94 0-5.33 2.39-5.33 5.326a5.334 5.334 0 0 0 5.33 5.326c2.94 0 5.33-2.39 5.33-5.326zm-11.955 6.869l-1.595 8.91H100l-3.786 22.298h11.756l1.595-8.86h-2.74l3.786-22.348H99.303zm42.84 22.597h1.595l-2.042 8.61h-6.028c-2.341 0-3.836-.497-4.733-1.393-2.142 1.294-5.03 1.991-7.82 1.991-2.84 0-5.58-.747-7.323-2.489-1.943-1.94-2.94-4.878-2.94-8.113 0-4.131 1.644-8.86 5.231-13.04 5.878-6.87 13.6-8.114 13.6-8.114l1.544 3.783.548-3.783h9.415l-3.338 20.607c-.149 1.194.947 1.94 2.292 1.94zm-11.158-5.077l1.545-9.01c-1.893.648-5.33 2.738-7.772 5.725-1.992 2.439-3.038 4.977-3.038 6.77 0 .945.249 1.642.846 2.09.449.348 1.047.497 1.744.497 2.44 0 6.028-2.09 6.675-6.072zm57.786-6.57c.15-.896.25-1.742.25-2.489 0-6.122-4.733-8.76-8.32-8.86-3.587-.05-6.625 1.294-8.32 3.684-1.992-2.489-4.732-3.534-6.874-3.534-2.44 0-3.786.597-4.583 1.095l.1-.797h-11.06l-1.394 8.86h1.744l-3.587 22.299h9.565l3.138-19.412c.697-4.23 2.092-4.878 3.238-4.878 1.046 0 2.341.349 2.341 2.887 0 .548-.05 1.195-.199 1.991l-3.138 19.412h9.016l3.288-19.412c.747-4.43 2.092-4.928 3.288-4.928 1.046 0 2.341.399 2.341 2.937 0 .548-.05 1.195-.2 1.991l-3.486 19.412h11.308l1.345-6.969h-2.242l2.441-13.29zM208 41.325A5.334 5.334 0 0 0 202.67 36c-2.94 0-5.33 2.39-5.33 5.326a5.334 5.334 0 0 0 5.33 5.326c2.939 0 5.33-2.39 5.33-5.326zm-11.956 6.87l-1.594 8.91h2.292l-3.786 22.298h11.756l1.594-8.86h-2.74l3.786-22.348h-11.308z"
        />
        <filter
          id="mia-filter"
          width="102.5%"
          height="109.1%"
          x="-1.2%"
          y="-2.3%"
          filterUnits="objectBoundingBox"
        >
          <feOffset dy="1" in="SourceAlpha" result="shadowOffsetOuter1" />
          <feGaussianBlur
            in="shadowOffsetOuter1"
            result="shadowBlurOuter1"
            stdDeviation=".5"
          />
          <feColorMatrix
            in="shadowBlurOuter1"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0"
          />
        </filter>
      </defs>
      <g fill="none" fillRule="evenodd" transform="translate(-45 -36)">
        <use fill="#000" filter="url(#mia-filter)" xlinkHref="#mia-path" />
        <use fill="#FFF" xlinkHref="#mia-path" />
      </g>
    </svg>
  )
}
