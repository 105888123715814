import React from 'react'
import styled from 'styled-components'
import { Box } from 'pcln-design-system'
import { ImageTile } from '@pcln/content-tile'
import analytics from '@/shared-utils/analytics'
import { ANALYTICS_CATEGORY_HOME } from '@/constants/analytics'
import generateLinkArray from './utils'

const StyledBox = styled(Box)`
  &:focus-within {
    outline: 2px solid #005fcc;
  }
`

type DestinationType = {
  driveSearchCity: string
  flySearchCity: string
  staySearchHref: string
  packageSearchHref?: string
  image: string
  imageWebP: string
  svg: () => React.ReactElement
  title: string
  width: number[]
}

function PopularDestinationTile({
  destination
}: {
  destination: DestinationType
}) {
  const {
    driveSearchCity,
    flySearchCity,
    image,
    imageWebP,
    packageSearchHref,
    staySearchHref,
    svg,
    title,
    width
  } = destination

  const productLinks = generateLinkArray({
    driveSearchCity,
    flySearchCity,
    staySearchHref,
    packageSearchHref
  })
  function handleNavOnClick(href: string) {
    analytics.fireEvent({
      action: 'SEO_Link',
      category: ANALYTICS_CATEGORY_HOME,
      label: `Generic Tile Click | ${flySearchCity}`
    })
    window.open(href, '_blank')
  }
  return (
    <StyledBox
      p={2}
      width={width}
      role="region"
      aria-describedby={`desc-${title}`}
    >
      <ImageTile
        handleNavOnClick={(href: string) => handleNavOnClick(href)}
        height="184px"
        image={image}
        imageWebP={imageWebP}
        navArray={productLinks}
        title={title}
        borderRadius="xl"
        role="img"
        aria-label={title}
        useLink
      >
        {svg()}
      </ImageTile>
    </StyledBox>
  )
}

export default PopularDestinationTile
