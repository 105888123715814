import React from 'react'
import styled from 'styled-components'
import { Card, Container, Flex, Box } from 'pcln-design-system'
import Skeleton from '@/components/Skeleton'

const ItemFlex = styled(Flex)`
  height: 379px;
`

function ItemCard() {
  return (
    <Card
      p={3}
      boxShadowSize="sm"
      borderWidth={0}
      width={[1, null, null, 1 / 3]}
      mx={[null, null, null, 2]}
      my={[2, null]}
      borderRadius="xl"
    >
      <Flex flexDirection="column">
        <Box mb={2}>
          <Skeleton height="200px" />
        </Box>
        <Box>
          <Box mb={1}>
            <Skeleton height="20px" />
          </Box>
          <Box mb={1}>
            <Skeleton height="20px" />
          </Box>
          <Box>
            <Skeleton height="20px" />
          </Box>
        </Box>
      </Flex>
    </Card>
  )
}
function DealCarouselLoader() {
  return (
    <Container maxWidth={1200} data-testid="deal-carousel-loader">
      <Card px={3} py={1} width={1} borderWidth={0}>
        <ItemFlex flexDirection={['column', null, null, 'row']}>
          <ItemCard />
          <ItemCard />
          <ItemCard />
        </ItemFlex>
      </Card>
    </Container>
  )
}

export default DealCarouselLoader
