import React from 'react'

export default function Seattle() {
  return (
    <svg width="149" height="36" viewBox="0 0 149 36">
      <defs>
        <path
          id="sea-path"
          d="M75.087 58.543c0-5.316-3.067-8.332-10.225-10.12-4.142-1.075-5.266-2.404-5.266-4.193 0-2.198 1.79-3.527 4.806-3.527 2.71 0 5.777 1.227 7.925 2.658l1.33-4.344C71.61 37.43 68.236 36 64.452 36c-6.596 0-10.584 3.63-10.584 8.64 0 4.395 2.556 7.82 8.998 9.405 5.01 1.33 6.442 2.556 6.442 5.112 0 2.71-2.352 4.242-5.42 4.242-4.192 0-7.413-2.2-9.458-4.14L53 64.165C55.096 66.006 58.778 68 63.89 68c5.778 0 11.197-2.863 11.197-9.457zM99.27 53.43c0 .973-.152 2.3-.357 3.068H84.188c-.153 3.374 1.585 7.208 7.516 7.208 2.505 0 5.47-1.125 7.158-2.607v4.293C96.766 67.03 93.545 68 89.864 68c-6.8 0-11.555-4.958-11.555-11.808 0-7.055 5.42-11.502 11.145-11.502 5.624 0 9.816 3.527 9.816 8.74zm-5.367-.1c.51-2.66-1.227-4.857-4.295-4.857-2.505 0-4.653 1.43-5.164 4.856h9.46zm33.233 14.057h-5.368l-.205-3.528c-1.84 2.71-5.113 4.14-8.232 4.14-5.98 0-10.378-5.01-10.378-11.655s4.397-11.655 10.38-11.655c3.118 0 6.39 1.43 8.23 4.14l.206-3.526h5.368v22.083zm-5.675-11.042c0-4.55-2.81-7.26-6.288-7.26s-6.29 2.71-6.29 7.26 2.813 7.26 6.29 7.26 6.29-2.71 6.29-7.26zm24.594-7.157v-3.884H141.4v-6.032h-5.93v6.032h-4.654v3.884h4.653v18.2h5.93v-18.2h4.653zm16.77 0v-3.884h-4.653v-6.032h-5.93v6.032h-4.653v3.884h4.653v18.2h5.93v-18.2h4.654zm9.612 18.2V36.715h-5.93v30.67h5.93zM198 53.43c0 .972-.153 2.3-.358 3.067h-14.725c-.153 3.374 1.585 7.208 7.516 7.208 2.505 0 5.47-1.125 7.158-2.607v4.294c-2.095 1.636-5.316 2.607-8.998 2.607-6.8 0-11.555-4.96-11.555-11.81 0-7.054 5.42-11.5 11.146-11.5 5.624 0 9.817 3.526 9.817 8.74zm-5.368-.102c.51-2.658-1.228-4.856-4.295-4.856-2.506 0-4.653 1.43-5.164 4.856h9.46z"
        />
        <filter
          id="sea-filter"
          width="105.5%"
          height="125%"
          x="-2.8%"
          y="-6.2%"
          filterUnits="objectBoundingBox"
        >
          <feOffset dy="2" in="SourceAlpha" result="shadowOffsetOuter1" />
          <feGaussianBlur
            in="shadowOffsetOuter1"
            result="shadowBlurOuter1"
            stdDeviation="1"
          />
          <feColorMatrix
            in="shadowBlurOuter1"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0"
          />
        </filter>
      </defs>
      <g fill="none" fillRule="evenodd" transform="translate(-51 -36)">
        <use fill="#000" filter="url(#sea-filter)" xlinkHref="#sea-path" />
        <use fill="#FFF" xlinkHref="#sea-path" />
      </g>
    </svg>
  )
}
