export function hasStringProp(o: unknown, prop: string): boolean {
  return (
    typeof o === 'object' &&
    o !== null &&
    typeof (o as Record<string, unknown>)[prop] === 'string'
  )
}

export function hasNumberProp(o: unknown, prop: string): boolean {
  return (
    typeof o === 'object' &&
    o !== null &&
    typeof (o as Record<string, unknown>)[prop] === 'number'
  )
}
