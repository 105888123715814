/* istanbul ignore file */
import React from 'react'
import { useRouter } from 'next/router'
import Head from 'next/head'
import { HeroModule } from '@pcln/marketing'
import { Box } from 'pcln-design-system'
import { IImageType } from '@pcln/contentful-components'
import useContentfulVariation from '@/hooks/useContentfulVariation'
import { SearchFormDesktop } from '@/components/SearchForm'
import TravelChatBot from '@/components/TravelChatBot'
import analytics from '@/shared-utils/analytics'
import { useFeature } from '@pcln/feature-components'
import { ANALYTICS_CATEGORY_HOME } from '@/constants/analytics'
import getCurrentTab from '@/shared-utils/getCurrentTab'
import { ProductType } from '@/types'
import { hasContentTypeId } from '@/shared-utils/content-model-type-guards'
import HomeContent from './HomeContent'

const kaleyImage =
  'https://s1.pclncdn.com/design-assets/next-landing/assets/images/hero/kaley-image-default.jpg'

const defaultHeroProps = {
  backgroundImage: {
    url: `${kaleyImage}?opto&auto=avif,webp`,
    altText: 'Go to your happy price'
  },
  imageClassName: 'hero-image',
  shouldDisplaySilhouette: true
}

const protocolRegex = /(^\w+:|^)/g
const queryRegex = /\?(.*)/g

function trimContentfulAssetUrl(url: string) {
  return url.replace(protocolRegex, '').replace(queryRegex, '')
}

const handleHeroImageClick = (
  url: string,
  gaAction?: string,
  gaLabel?: string
) => {
  analytics.fireEvent({
    category: ANALYTICS_CATEGORY_HOME,
    action: gaAction ?? 'hero click',
    label: gaLabel
  })
  window.open(url)
}

function isIImageType(content: unknown): content is IImageType {
  return hasContentTypeId(content) && content.contentTypeId === 'image'
}

export default function HomeDesktop() {
  const { push, query, pathname } = useRouter()
  const currentTab = getCurrentTab(query)
  const darkFill = useFeature('siteExperience.darkFill')
  const heroImage = useContentfulVariation('heroImage', isIImageType)
  const { openPenny, askPrompt } = query

  const openPennyProps = {
    openPenny: typeof openPenny === 'string' ? openPenny : '',
    askPrompt: typeof askPrompt === 'string' ? askPrompt : ''
  }
  const onFormChange = (productType: ProductType) => {
    void push(
      {
        pathname,
        query: {
          ...query,
          tab: productType
        }
      },
      undefined,
      { shallow: true }
    )
    analytics.fireGA4Event({
      event: 'internal_element',
      attributes: {
        type: 'search_form',
        page_name: 'homepage',
        link_name: productType === 'vacations' ? 'bundle_and_save' : productType
      }
    })
  }
  const heroImageContentfulAsset = heroImage?.asset
  const heroImageContentfulCTA = heroImage?.callToAction
  const heroProps = heroImageContentfulAsset?.file?.url
    ? {
        ...defaultHeroProps,
        searchForms: (
          <SearchFormDesktop
            currentTab={currentTab}
            onFormChange={onFormChange}
          />
        ),
        backgroundImage: {
          url: `https:${trimContentfulAssetUrl(
            heroImageContentfulAsset.file.url
          )}?fm=webp&w=1180&h=840&q=80`,
          altText: heroImageContentfulAsset.title ?? ''
        },
        handleImageClick: heroImageContentfulCTA?.url
          ? (event: KeyboardEvent) => {
              const { url, analyticsTracking } = heroImageContentfulCTA
              if (url) {
                event.preventDefault()
                handleHeroImageClick(
                  url,
                  analyticsTracking?.action,
                  analyticsTracking?.label
                )
              }
            }
          : null,
        ariaLabel: heroImageContentfulCTA
          ? heroImageContentfulCTA.ariaLabel
          : null,
        isDarkerFill: darkFill
      }
    : {
        ...defaultHeroProps,
        searchForms: (
          <SearchFormDesktop
            currentTab={currentTab}
            onFormChange={onFormChange}
          />
        ),
        handleImageClick: (event: KeyboardEvent) => {
          event.preventDefault()
          handleHeroImageClick('https://www.priceline.com/vacationpackages')
        },
        ariaLabel: 'vacation packages',
        isDarkerFill: darkFill
      }
  return (
    <>
      <Head>
        <link
          rel="preload"
          as="image"
          fetchPriority="high"
          href={heroProps.backgroundImage.url}
        />
      </Head>
      <Box style={{ overflowX: 'hidden' }}>
        <Box height="100%" data-testid="hero-outer-box">
          <HeroModule {...heroProps} />
        </Box>
        <HomeContent />
      </Box>
      <TravelChatBot
        enableChatBot={currentTab === 'hotels'}
        {...openPennyProps}
      />
    </>
  )
}
