import React from 'react'
import { Box } from 'pcln-design-system'
import MerchModuleWrapper from '@/components/MerchModuleWrapper'
import useContentfulVariation from '@/hooks/useContentfulVariation'
import { isIMerchModule } from '@/shared-utils/content-model-type-guards'

export default function CreditCard() {
  const promo = useContentfulVariation('creditCardPromo', isIMerchModule)
  return promo != null ? (
    <Box>
      <MerchModuleWrapper
        content={{ ...promo, backgroundColor: 'background.light' }}
      />
    </Box>
  ) : null
}
