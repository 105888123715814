import {
  Atlanta,
  Dallas,
  Denver,
  LasVegas,
  Miami,
  NewYork,
  LosAngeles,
  Orlando,
  SanDiego,
  SanFrancisco,
  Seattle
} from './svgs'

const imageFolder =
  'https://s1.pclncdn.com/design-assets/next-landing/assets/images/destinations/popular/'
const DESTINATIONS = [
  {
    driveSearchCity: 'Las Vegas, NV',
    flySearchCity: 'Las Vegas',
    staySearchHref: '/hotel-deals/c3000015284/US/Nevada/Las-Vegas-Hotels.html',
    packageSearchHref: 'https://www.priceline.com/partner/v2/packages-lasvegas',
    image:
      'https://s1.pclncdn.com/design-assets/hero/city/3000015284.jpg?opto&auto=avif,webp',
    imageWebP:
      'https://s1.pclncdn.com/design-assets/hero/city/3000015284.jpg?opto&auto=avif,webp',
    svg: LasVegas,
    title: 'JACKPOT JOY',
    width: [1, 1, 1, 1, 2 / 3]
  },
  {
    driveSearchCity: 'New York, NY',
    flySearchCity: 'New York',
    staySearchHref: '/hotel-deals/c3000016152/US/New-York/New-York-Hotels.html',
    image: `${imageFolder}new-york-city.jpg?opto&auto=avif,webp`,
    imageWebP: `${imageFolder}new-york-city.jpg?opto&auto=avif,webp`,
    svg: NewYork,
    title: '24-HOUR EXCITEMENT',
    width: [1, 1, 1 / 2, 1 / 2, 1 / 3]
  },
  {
    driveSearchCity: 'Los Angeles, CA',
    flySearchCity: 'Los Angeles',
    staySearchHref:
      '/hotel-deals/c3000001947/US/California/Los-Angeles-Hotels.html',
    image: `${imageFolder}los-angeles.jpg?opto&auto=avif,webp`,
    imageWebP: `${imageFolder}los-angeles.jpg?opto&auto=avif,webp`,
    svg: LosAngeles,
    title: 'CUTTING-EDGE COOL',
    width: [1, 1, 1 / 2, 1 / 2, 1 / 3]
  },
  {
    driveSearchCity: 'Orlando, FL',
    flySearchCity: 'Orlando',
    staySearchHref: '/hotel-deals/c3000003349/US/Florida/Orlando-Hotels.html',
    image: `${imageFolder}orlando.jpg?opto&auto=avif,webp`,
    imageWebP: `${imageFolder}orlando.jpg?opto&auto=avif,webp`,
    svg: Orlando,
    title: 'THEME PARK PARADISE',
    width: [1, 1, 1 / 2, 1 / 2, 1 / 3]
  },
  {
    driveSearchCity: 'Atlanta, GA',
    flySearchCity: 'Atlanta',
    staySearchHref: '/hotel-deals/c3000003496/US/Georgia/Atlanta-Hotels.html',
    image: `${imageFolder}atlanta.jpg?opto&auto=avif,webp`,
    imageWebP: `${imageFolder}atlanta.jpg?opto&auto=avif,webp`,
    svg: Atlanta,
    title: 'SWEET SOUTHERN CHARM',
    width: [1, 1, 1 / 2, 1 / 2, 1 / 3]
  },
  {
    driveSearchCity: 'Dallas, TX',
    flySearchCity: 'Dallas',
    staySearchHref: '/hotel-deals/c3000021082/US/Texas/Dallas-Hotels.html',
    image: `${imageFolder}dallas.jpg?opto&auto=avif,webp`,
    imageWebP: `${imageFolder}dallas.jpg?opto&auto=avif,webp`,
    svg: Dallas,
    title: 'FOOD CAPITAL',
    width: [1, 1, 1 / 2, 1 / 2, 1 / 3]
  },
  {
    driveSearchCity: 'San Francisco, CA',
    flySearchCity: 'San Francisco',
    staySearchHref:
      '/hotel-deals/c3000002244/US/California/San-Francisco-Hotels.html',
    image: `${imageFolder}san-francisco.jpg?opto&auto=avif,webp`,
    imageWebP: `${imageFolder}san-francisco.jpg?opto&auto=avif,webp`,
    svg: SanFrancisco,
    title: 'SOAK UP THE SCENES',
    width: [1, 1, 1 / 2, 1 / 2, 1 / 3]
  },
  {
    driveSearchCity: 'Miami, FL',
    flySearchCity: 'Miami',
    staySearchHref: '/hotel-deals/c3000003311/US/Florida/Miami-Hotels.html',
    image: `${imageFolder}miami.jpg?opto&auto=avif,webp`,
    imageWebP: `${imageFolder}miami.jpg?opto&auto=avif,webp`,
    svg: Miami,
    title: "IT'S BEACH TIME",
    width: [1, 1, 1 / 2, 1 / 2, 1 / 3]
  },
  {
    flySearchCity: 'Denver',
    driveSearchCity: 'Denver, CO',
    staySearchHref: '/hotel-deals/c3000002573/US/Colorado/Denver-Hotels.html',
    image: `${imageFolder}denver.jpg?opto&auto=avif,webp`,
    imageWebP: `${imageFolder}denver.jpg?opto&auto=avif,webp`,
    svg: Denver,
    title: 'MILE-HIGH MAGIC',
    width: [1, 1, 1 / 2, 1 / 2, 1 / 3]
  },
  {
    flySearchCity: 'Seattle',
    driveSearchCity: 'Seattle, WA',
    staySearchHref:
      '/hotel-deals/c3000023414/US/Washington/Seattle-Hotels.html',
    image: `${imageFolder}seattle.jpg?opto&auto=avif,webp`,
    imageWebP: `${imageFolder}seattle.jpg?opto&auto=avif,webp`,
    svg: Seattle,
    title: 'PACIFIC NORTHWEST BEAUTY',
    width: [1, 1, 1 / 2, 1 / 2, 1 / 3]
  },
  {
    driveSearchCity: 'San Diego, CA',
    flySearchCity: 'San Diego',
    staySearchHref:
      '/hotel-deals/c3000002241/US/California/San-Diego-Hotels.html',
    image: `${imageFolder}san-diego.jpg?opto&auto=avif,webp`,
    imageWebP: `${imageFolder}san-diego.jpg?opto&auto=avif,webp`,
    svg: SanDiego,
    title: 'RIDE THE WAVES',
    width: [1, 1, 1 / 2, 1 / 2, 1 / 3]
  }
]

export default DESTINATIONS
