import React from 'react'
import useGeoPosition from '@/hooks/useGeoPosition'
import { Heading, Hide, Box } from 'pcln-design-system'
import useFetchPackages from './useFetchPackages'
import { PackagesCarouselGaCategories } from './types'
import DealCarouselLoader from './DealCarouselLoader'
import PackagesDealCarousel from './PackageDealCarousel'

interface PackagesCarouselProps {
  heading?: string
  headingMobile?: string
  gaCategory: PackagesCarouselGaCategories
}

export default function PackagesCarousel({
  heading = "Don't miss these package deals",
  headingMobile = "Don't miss these deals",
  gaCategory
}: PackagesCarouselProps) {
  const { longitude, latitude } = useGeoPosition()
  const { deals, isLoading } = useFetchPackages({
    latitude,
    longitude
  })

  if (isLoading) {
    return <DealCarouselLoader />
  }

  return deals.length > 0 ? (
    <Box>
      <Heading
        ml={2}
        pb={20}
        fontSize={4}
        fontWeight="bold"
        m={0}
        color="text.heading"
        textStyle="heading2"
      >
        <Hide xs>{heading}</Hide>
        <Hide sm md lg xl xxl>
          {headingMobile}
        </Hide>
      </Heading>
      <PackagesDealCarousel gaCategory={gaCategory} deals={deals} />
    </Box>
  ) : null
}
