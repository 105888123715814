import React from 'react'
import { Box, Flex, Heading } from 'pcln-design-system'
import PopularDestinationTile from './PopularDestinationTile'
import DESTINATIONS from './constants'

function PopularDestination() {
  return (
    <Box>
      <Heading color="text.heading" m={0} mb={4} textStyle="heading2">
        Discover deals in every city
      </Heading>
      <Flex flexWrap="wrap" m={-2}>
        {DESTINATIONS.map(destination => (
          <PopularDestinationTile
            destination={destination}
            key={destination.title}
          />
        ))}
      </Flex>
    </Box>
  )
}

export default PopularDestination
