import React from 'react'
import { Carousel } from 'pcln-carousel'
import { PackagesCarouselGaCategories, DealDataResponse } from './types'
import PackageCard from './PackageCard'

export default function PackagesDealCarousel({
  deals,
  gaCategory
}: {
  gaCategory: PackagesCarouselGaCategories
  deals: DealDataResponse[]
}) {
  return (
    <Carousel
      arrowsPositions="top"
      arrowsPosition="side"
      currentSlide={0}
      displayArrowsMobile={false}
      slideSpacing={1}
      visibleSlides={[2.5, 2.5, 3]}
      mobileVisibleSlides={[1.2, 1.2, 2.1]}
    >
      {deals.map((deal, index) => {
        return <PackageCard index={index} gaCategory={gaCategory} deal={deal} />
      })}
    </Carousel>
  )
}
