import React from 'react'

export default function Denver() {
  return (
    <svg width="153" height="36" viewBox="0 0 153 36">
      <defs>
        <path
          id="den-path"
          d="M68.02 67.45H53.045l.23-2.193 3.708-.777V39.886c0-.55-.275-.777-.916-.915L53 38.24l.32-2.24h14.882c11.22 0 15.89 6.4 15.89 14.766 0 9.188-6.594 16.685-16.073 16.685zm-3.71-2.97l3.572.64c5.586-1.143 9.203-6.4 9.203-12.89 0-6.95-2.884-13.213-9.57-13.213H63.44V63.61c0 .55.23.733.87.87zm40.753-2.15l.916 1.42c-3.39 2.604-6.045 4.25-9.434 4.25-6.227 0-10.028-4.57-10.028-11.11 0-7.22 6.594-12.16 12.134-12.16 4.488 0 7.418 3.293 7.418 8.64 0 .55 0 1.1-.09 1.647H92.516c0 4.663 1.877 9.554 6.73 9.554 1.33 0 3.206-.776 5.816-2.24zm-4.35-9.78v-.87c0-2.97-1.603-4.663-3.938-4.663-.687 0-1.374.137-2.106.412-1.01 1.233-1.74 3.2-2.016 5.484l8.06-.365zm33.7 14.9l.276-2.1-2.657-.64c-.595-.14-.824-.413-.824-.87V51.177c0-4.754-3.07-6.446-6.503-6.446-.595 0-1.1.047-1.65.14l-5.86 3.016V44.73c-3.113.366-6.227.64-9.34 1.006l.09 2.057 3.435.732v16.137l-3.205.686-.275 2.102h11.95l.276-2.102-2.106-.64c-.458-.138-.825-.412-.825-.87V49.807l4.26-1.83c.55-.227 1.006-.32 1.464-.32 1.512 0 2.474 1.51 2.474 3.338v13.67l-2.565.685-.275 2.1h11.86zm14.883 0l8.105-19.427 2.153-.55.275-2.193h-8.655l-.412 2.15 2.61.593c.32.09.46.274.46.594 0 .183-.092.55-.23.914l-4.35 10.927-5.357-12.434 3.022-.594.275-2.15h-11.677l-.412 2.194 1.97.595c.458.136.733.41.96.867L146.963 68l2.335-.55zm30.36-5.12l.914 1.42c-3.388 2.604-6.044 4.25-9.432 4.25-6.228 0-10.028-4.57-10.028-11.11 0-7.22 6.593-12.16 12.134-12.16 4.487 0 7.418 3.293 7.418 8.64 0 .55 0 1.1-.092 1.647h-13.462c0 4.663 1.878 9.554 6.73 9.554 1.33 0 3.207-.776 5.817-2.24zm-4.35-9.78v-.87c0-2.97-1.604-4.663-3.94-4.663-.686 0-1.373.137-2.106.412-1.007 1.233-1.74 3.2-2.014 5.484l8.06-.365zm20.467 14.9l.274-2.1-3.434-.64c-.595-.14-.824-.367-.824-.87V49.85l3.57-1.6c.733.32 1.42.916 1.42 2.15v1.234h1.832c1.877 0 3.388-1.188 3.388-3.29 0-2.56-2.29-3.613-4.35-3.613-.412 0-.87.047-1.282.184l-4.58 3.017v-3.2l-9.386 1.007.137 2.057 3.434.732v16.137l-3.252.686-.228 2.1h13.28z"
        />
        <filter
          id="den-filter"
          width="105.4%"
          height="125%"
          x="-2.7%"
          y="-6.2%"
          filterUnits="objectBoundingBox"
        >
          <feOffset dy="2" in="SourceAlpha" result="shadowOffsetOuter1" />
          <feGaussianBlur
            in="shadowOffsetOuter1"
            result="shadowBlurOuter1"
            stdDeviation="1"
          />
          <feColorMatrix
            in="shadowBlurOuter1"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0"
          />
        </filter>
      </defs>
      <g fill="none" fillRule="evenodd" transform="translate(-51 -36)">
        <use fill="#000" filter="url(#den-filter)" xlinkHref="#den-path" />
        <use fill="#FFF" xlinkHref="#den-path" />
      </g>
    </svg>
  )
}
