import React from 'react'
import styled, { keyframes } from 'styled-components'

const animation = ({ animationWidth }: { animationWidth: number }) => keyframes`
  0% {
    transform: translateX(-200px)
  }
  100% {
    transform: translateX(${animationWidth}px)
  }
`

const Wrapper = styled.span<{ height?: string; width?: string }>`
  background-color: #eee;
  background-size: 200px 100%;
  background-repeat: no-repeat;
  display: inline-block;
  line-height: 1;
  width: ${props => (props.width ? props.width : '100%')};
  ${({ height }: { height?: string }) => height && `height: ${height};`}
  overflow: hidden;
`

const Glare = styled.span`
  display: inline-block;
  background-image: linear-gradient(
    to right,
    rgba(245, 245, 245, 0) 0%,
    rgba(245, 245, 245, 1) 50%,
    rgba(245, 245, 245, 0) 100%
  );
  background-image: -webkit-linear-gradient(
    left,
    rgba(245, 245, 245, 0) 0%,
    rgba(245, 245, 245, 1) 50%,
    rgba(245, 245, 245, 0) 100%
  );
  animation: ${animation} 1.2s ease-in-out infinite;
  height: 100%;
  width: 200px;
`

function Skeleton({
  height,
  width,
  animationWidth = 100
}: {
  height?: string
  width?: string
  animationWidth?: number
}) {
  return (
    <Wrapper height={height} width={width}>
      <Glare animationWidth={animationWidth + 200}>&zwnj;</Glare>
    </Wrapper>
  )
}

export default Skeleton
